<template>
    <div class="productCenter_page">
        <Banner :adCategory="4"></Banner>
        <div class="breadcrumb_box flex-center">
            <Breadcrumb :breadcrumbList="breadcrumbList"></Breadcrumb>
        </div>
        <div class="content main flex">
            <div class="sidebar" >
                <el-menu  :default-active="indexs+'-'+childrenIndex" class="el-menu-vertical-demo" background-color="#f4f5f5"
                    :unique-opened="true" text-color="#333333" active-text-color="#E52830" @open="handleOpen">
                    <div v-for="(item,gIndex) in goodsClassification" :key="gIndex">
                        <el-submenu :index="gIndex+''">
                            <template #title>
                                <div class="line" v-if="indexs == gIndex"></div>
                                <span>{{ item.label }}{{ }}</span>
                            </template>
                            <div v-for="(cItem,cIndex) in item.children" :key="cIndex">
                                <el-menu-item-group>
                                    <el-menu-item :index="gIndex + '-' + cIndex"
                                        @click.native.prevent="selecetBtn(cItem)">{{ cItem.label}}</el-menu-item>
                                </el-menu-item-group>
                            </div>

                        </el-submenu>

                    </div>

                </el-menu>
            </div>
            <div class="goods_content">
                <div class="goods flex flex-wrap">
                    <div class="goods_list pointer" v-for="(item,index) in goodsList" :key="index"
                        @click="toDetails(item)">
                        <div class="goodsImgBox display">
                            <img class="goodsImg" :src="item.coverUrl" alt="" />
                        </div>
                        <div class="goodsName display ">
                            <div class="nane row">{{ item.productName }}</div>
                        </div>
                    </div>
                </div>
                <div class="pages">
                    <pages :params="params" :total="total" @nextPage="nextPage">
                    </pages>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/breadcrumb'
import Pages from '@/components/pages'
import Banner from '@/components/banner'
export default {
    components:{
        Pages,
        Breadcrumb,
        Banner
    },
    data(){
        return{
            indexs: null,
            childrenIndex:0,
            breadcrumbList: [
                {
                    name: "产品中心",
                    url: ''
                },
            ],
            goodsList:[],
            goodsClassification: [],
            params: {
                page: 1,
                limit: 12,
                catGrade:2,
                catId: "",
                goodsName: "",
            },
            total: 10,
        }
    },
     watch:{
      '$store.state.typeId'(){
            this.init()
      }
    },
    created(){
        
    },
    mounted(){
        this.init()
        this.getCassification()
    },
    // // 监听路由变化
    // watch: {
    //     $route: "urlName",
    // },
    methods: {
        init(){
            let navBarData =JSON.parse(localStorage.getItem('navBarData') )
            console.log(navBarData);
            this.breadcrumbList = [
                {
                    name: "产品中心",
                    url: ''
                },
                {
                    name: this.$route.query.name,
                    url: ''
                }
            ]
            this.indexs = Number(navBarData.index)
            this.getCassification()
        },
        getGoodsList(){
            this.api.findPageGoodsList(this.params).then(res=>{
                this.total = res.count
                this.goodsList = res.data
            })
        },
        //获取商品分类
        getCassification(){
            this.api.findGoodsClassification().then(res=>{
                 let isService = localStorage.getItem('isService')
                  if(isService == 1){
                    res.data.forEach((item,index)=>{
                        item.children.forEach((items,indexs)=>{
                            if(items.id == this.$route.query.id){
                                this.indexs = index
                                this.childrenIndex = indexs
                                this.params.catId = items.id
                                this.getGoodsList()
                            }
                        })
                        
                    })
                     this.goodsClassification = res.data
                }else{
                    this.goodsClassification = res.data
                    // this.goodsClassification[this.indexs]
                    this.childrenIndex =  0
                    this.params.catId = this.goodsClassification[this.indexs].children[0].id
                    this.getGoodsList()

                }
            })
        },
        toDetails(item){
            let navBarData =JSON.parse(localStorage.getItem('navBarData') )
            navBarData.index =Number(this.indexs) 
            localStorage.setItem('navBarData',JSON.stringify(navBarData))
            this.$router.push({
                path:"/goodsDetails",
                query:{
                    goodsId: item.id,
                    goodsName: item.productName,
                }
            })
        },
        selecetBtn(item){
            this.params.catId = item.id
            this.params.page = 1
            this.getGoodsList()
        },
        nextPage(page){
            this.params.page = page
            this.getGoodsList()
        },
        handleOpen(val){
            console.log(this.goodsClassification[val].label);
            document.title = this.goodsClassification[val].label + '—果尔佳'
            this.indexs = val
            this.childrenIndex =  null
        },
       
    }
}
</script>
<style lang="scss" scoped>






    .productCenter_page{
        .breadcrumb_box{
            background: #f4f5f5;
        }
        .main{
            margin-top: 40px;
            padding-bottom: 50px;
            .sidebar{
                min-width: 268px;
                height: 700px;
                overflow: hidden;
                overflow-y: scroll;
            }
            
            .goods_content {
                margin-left: 46px;
                
                .goods{
                    .goods_list:nth-child(3n+1){
                        margin-left: 0px !important;
                    }
                    .goods_list:hover{
                        border: 1px solid #e62129 !important;
                        .goodsName{
                            background: #e62129 !important;
                            color: white !important;
                        }
                    }
                    .goods_list{
                        width: 280px;
                        height: 338px;
                        border: 1px solid rgba(222, 222, 222, 0.9600);
                        margin-left: 22px;
                        margin-bottom: 24px;
                        .goodsImgBox{
                            width: 278px;
                            height: 278px;
                        }
                        .goodsImg{
                            width: 200px;
                            height: 200px;
                        }
                        .goodsName{
                            width: 278px;
                            height: 57px;
                            background: #f4f5f5;
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            padding:0 20px ;
                            box-sizing: border-box;
                        }
                    }
                }
            }
        }
        .pages{
            display: flex;
            justify-content: flex-end;
        }
        ::v-deep.el-submenu__title{
            font-size: 16px;
            font-weight: 500;
            
        }
        ::v-deep.el-menu-item{
            font-size: 14px;
            color: #666666 ;
        }
    }
</style>
<style>


    .el-submenu .el-menu-item{
        background-color: white !important;
    }
    .el-menu-item-group__title{
        display: none;
    }
    .el-submenu{
        border-bottom: 1px solid #fff;
        position: relative;
    }
    .el-submenu .line{
        width: 2px;
        height: 100%;
        background: #e71f28;
        position: absolute;
        top:0;
        left: 0;
    }
    .el-menu{
        border-right: none !important;
    }
</style>